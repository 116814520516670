<template>
  <div class="Open">
    <div class="formWrap">
      <span class="formName">数据消费方</span>
      <el-input
        class="formBody"
        v-model="sourceTitle"
        clearable
        placeholder="请输入内容"
      ></el-input>
      <el-button class="mr10" type="primary" @click="onList">查询</el-button>
      <el-button @click="onReList">重置</el-button>
    </div>
    <el-button type="primary" icon="el-icon-plus" @click="handleAdd()"
      >添加</el-button
    >
    <el-table :data="listData" class="list">
      <el-table-column type="index" width="50"> </el-table-column>
      <el-table-column
        label="数据消费方"
        prop="sourceTitle"
        width="220"
      ></el-table-column>
      <el-table-column
        label="别名"
        prop="anotherNamer"
      ></el-table-column>
      <el-table-column
        label="已绑定分发规则数量"
        prop="anotherNamer"
        width="180"
      >
        <template slot-scope="scope">
          {{scope.row.ruleIds.length}}
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="updateTime"
        label="修改时间"
        width="180"
      ></el-table-column>
      <el-table-column label="操作" width="260">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button size="mini" @click="onRules(scope.row)">绑定分发规则</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.row)"
            >删除</el-button
          >

        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      @current-change="onList"
      :current-page.sync="pageNum"
      :page-size="10"
      layout="total, prev, pager, next"
      :total="totals"
    >
    </el-pagination>

    <el-dialog title="数据分发" :visible.sync="dialogFormVisible" @close="handleClose">
      <el-form label-position="left" :model="form">
        <el-form-item label="数据消费方" :label-width="formLabelWidth">
          <el-input v-model="form.sourceTitle" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="别名" :label-width="formLabelWidth">
          <el-input v-model="form.anotherNamer" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose()">取消</el-button>
        <el-button type="primary" @click="handleConfirm()">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="绑定分发规则" :visible.sync="showRules" @close="handleCloseRules">
      选择分发规则
      <el-select v-model="ruleIds" multiple placeholder="请选择" style="width: 500px; margin-left: 10px;">
        <el-option
          v-for="item in ruleList"
          :key="item.id"
          :label="item.ruleName"
          :value="item.id">
        </el-option>
      </el-select>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCloseRules()">取消</el-button>
        <el-button type="primary" @click="handleConfirmRules()">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
    
<style lang="less" scoped>
.Open {
  padding: 20px;
}
</style>
    
    <script>
export default {
  name: "Open",
  components: {},
  data() {
    return {
      sourceTitle: "",
      listData: [],
      pageNum: 1,
      pageSize: 10,
      totals: 0,

      dialogFormVisible: false,
      form: {
        id: '',
        sourceTitle: '',
        anotherNamer: '',
      },
      formLabelWidth: '100px',

      showRules: false,
      configId: null,
      ruleList: null,
      ruleIds: null,
    };
  },
  computed: {},
  mounted() {
    this.onList()
  },
  methods: {
    goto: function (path) {
      this.$router.push({
        name: path,
      });
    },
    // 获取列表数据
    onList: function () {
      var that = this;
      var data = {
        sourceTitle: this.sourceTitle,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-iot/thirdParty/list",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.listData = res.data.list;
          that.totals = res.data.totals;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 重置列表
    onReList: function () {
      this.sourceTitle = "";
      this.pageNum = 1;
      this.totals = 0;
      this.onList();
    },
    // 添加
    handleAdd: function () {
      this.form.id = ''
      this.form.sourceTitle = ''
      this.form.anotherNamer = ''
      this.dialogFormVisible = true
    },
    // 编辑
    handleEdit: function (row) {
      console.log('row', row);
      this.form.id = row.id
      this.form.sourceTitle = row.sourceTitle
      this.form.anotherNamer = row.anotherNamer
      this.dialogFormVisible = true
    },
    // 取消
    handleClose: function () {
      this.form.id = ''
      this.form.sourceTitle = ''
      this.form.anotherNamer = ''
      this.dialogFormVisible = false
    },
    // 确定
    handleConfirm: function () {
      this.$confirm("此操作将保存数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.onConfirm();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 提交数据
    onConfirm: function () {
      var that = this;
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-iot/thirdParty/submit",
          data: JSON.stringify(that.form),
        })
        .then((res) => {
          if (res.success) {
            that.$message({
              type: "success",
              message: "操作成功",
            });
          } else {
            that.$message({
              type: "info",
              message: res.msg,
            });
          }
          that.handleClose()
          that.onList()
        })
        .catch((err) => {
          console.log(err);
        });
    },
     // 删除
     handleDelete(row) {
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.onDelete(row.id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 执行删除
    onDelete: function (id, agin) {
      var that = this;
      var data = {
        id: id,
      };
      if (agin) {
        data.deletionState = 1
      }
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-iot/thirdParty/delete-check",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.$message({
              type: "success",
              message: "操作成功",
            });
          } else {
            that.onDeleteFail(id, res.msg)
          }
          that.onList();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 删除失败
    onDeleteFail: function (id, msg) {
      this.$confirm(msg, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.onDelete(id, true);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 获取可用规则

    // 绑定规则
    onRules: function (row) {
      this.configId = row.id
      this.ruleIds = JSON.parse(JSON.stringify(row.ruleIds))
      var that = this;
      var data = {
        thirdPartyId: row.id,
        pageNum: 0,
        pageSize: 0,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-iot/rule/list",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.ruleList = res.data.list;
          that.showRules = true
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 取消规则
    handleCloseRules: function () {
      this.configId = null
      this.ruleIds = null
      this.ruleList = null
      this.showRules = false
    },
    // 提交规则
    handleConfirmRules: function () {
      this.$confirm("此操作将保存数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.handleSaveRules({
            id: this.configId,
            ruleIds: this.ruleIds
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 保存规则
    handleSaveRules: function (data) {
      var that = this;
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-iot/thirdParty/configurationRule",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            this.$message({
              type: "success",
              message: "操作成功",
            });
          } else {
            this.$message({
              type: "warning",
              message: res.msg,
            });
          }
          this.handleCloseRules()
          this.onList()
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
    